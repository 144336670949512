<script>
import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import Pagination from "@/components/Components/Pagination/Pagination";
import MarketplaceFilter from "@/views/MarketplaceCategoryPage/MarketplaceFilter/MarketplaceFilter";
import ProductCategoriesGrid from "@/views/MarketplaceProductCategories/ProductCategoriesGrid/ProductCategoriesGrid";
import ProductCategoryTitle from "@/views/MarketplaceProductCategories/ProductCategoryTitle/ProductCategoryTitle";
import MarketplaceSortFilter from "@/views/MarketplaceCategoryPage/MarketplaceSortFilter/MarketplaceSortFilter";
import CountdownComponent from "@/components/Components/CountdownComponent/CountdownComponent.vue";

export default {
  name: 'MarketplaceProductCategories',
  components: {
    CountdownComponent,
    Button,
    CheckBox,
    Pagination,
    MarketplaceFilter,
    ProductCategoriesGrid,
    MarketplaceSortFilter,
    ProductCategoryTitle,
    AboutButton
  },
  data() {
    return {
      categories: [],
      roomName: null,
    }
  },
  watch: {
    '$route.query': {
      handler: 'getProductCategories',
      deep: true,
      immediate: true
    }
  },
  computed: {
    isPromoPage() {
      return this.$store.state.utils.showPromoBackground;
    }
  },
  methods: {
    async getProductCategories() {
      this.roomName = this.$route.query.room ?? null
      let staticCategories = [];
      if (this.isPromoPage) {
        staticCategories = await this.$store.dispatch('product/getStaticPromoProductCategories');
      }
       const categories = await this.$store.dispatch('product/getProductCategories', {
        room: this.roomName,
        promo: this.isPromoPage
      });
      this.categories = categories;
      //  const categoriesFiltered = categories.filter((item) => {
      //    return ![169,170,171,172,173,174,175,130,168,18,10,71,109,129,26,88,87].includes(item.id)
      //  })
      // this.categories = staticCategories.concat(categoriesFiltered);
    }
  },
  async mounted() {
    this.getProductCategories();
  },
}
</script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container">
    <div class="row">
      <div class="d-flex  justify-content-center mb-5" v-if="isPromoPage">
        <div class="text-center">
          <div class="promo-title mb-5">
            {{ $t('promo-valid-until')}}
          </div>
          <CountdownComponent v-if="false"/>
        </div>
      </div>
      <div class="col-md-12 products-container" v-bind:class="{ 'open-filter-container-mobile': filterContainerActive }">
        <div class="d-flex">
          <div class="me-auto" v-if="roomName">
            <ProductCategoryTitle :title="$t(roomName)"/>
          </div>
        </div>
        <div>
          <ProductCategoriesGrid :categories="categories" :is-promo="isPromoPage"/>
        </div>
      </div>
    </div>
  </div>
</template>

